.submissions {
  > .table-responsive {
    width: 100%;
    thead {
      // border-bottom: 1px solid $theme-grey-10;
      tr.header-row::after {
        content: '';
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0px;
        border-bottom: 1px solid $theme-grey-10;
        pointer-events: none;
        z-index: 1;
      }
      tr.header-row {
        position: relative;
      }
    }
    tbody {
      // border-bottom: 1px solid $theme-grey-10;
      tr::after {
        content: '';
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0px;
        border-bottom: 1px solid $theme-grey-10;
        pointer-events: none;
        z-index: 1;
      }
      tr {
        position: relative;
        td {
          height: 4.5rem !important;
        }
      }
    }
  }
  .divider {
    text-orientation: mixed;
    writing-mode: vertical-rl;
    display: flex;
    rotate: 180deg;
    justify-content: center;
    background-color: $theme-white-2;
    padding: 0.6rem;
    border-left: 1px solid $theme-grey-1;
    align-items: center;
    line-height: 0;
  }
}

.submission-filter {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;

  .custom-input-box {
    width: min(100%, 200px);
  }
  .query-filter {
    .query-row {
      > div {
        &:not(:last-child) {
          width: 200px;
        }
      }
    }
    .icon-div {
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
  }

  .filter-modal {
    position: absolute;
    top: 42px;
    right: 0;
    width: 200px;
    background-color: $theme-white-1;
    border: 1px solid $theme-grey-1;
    border-radius: 5px;
    z-index: 99;

    .version-list {
      max-height: 145px;
      overflow-y: auto;
    }
  }

  .create-view-modal {
    position: absolute;
    top: 42px;
    right: 0;
    width: 200px;
    height: auto;
    max-height: 70vh;
    overflow-y: scroll;
    background-color: $theme-white-1;
    border: 1px solid $theme-grey-1;
    border-radius: 5px;
    z-index: 99;

    section {
      &:not(:last-child) {
        border-bottom: 1.5px solid $theme-grey-8;
      }
    }
  }

  .show-view-list-modal {
    position: absolute;
    top: 42px;
    right: 0;
    width: 200px;
    background-color: $theme-white-1;
    border: 1px solid $theme-grey-1;
    border-radius: 5px;
    z-index: 99;

    .view-name-label {
      padding: 5px 1.5px;
      &:hover {
        color: $theme-green-1;
        background-color: $theme-green-2;
      }
    }
  }

  .manage-view-modal {
    position: absolute;
    top: 42px;
    right: 0;
    width: auto;
    height: 250px;
    overflow-y: scroll;
    background-color: $theme-white-1;
    border: 1px solid $theme-grey-1;
    border-radius: 5px;
    z-index: 99;
    tr {
      border-bottom: 1px solid $theme-grey-10;
      &:hover {
        td {
          color: $theme-green-1 !important;
        }
        background-color: $theme-green-2;
      }
    }
  }

  .manage-view-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem;
    }
    
    > div:last-child {
      margin-left: auto;
    }
  }
}

html[dir='rtl'] {
  .create-view-modal,
  .show-view-list-modal,
  .manage-view-modal {
    left: 0;
    right: auto;
  }
  .manage-view-header{
    > div:last-child {
      margin-left: 0;
      margin-right: auto;
    }
  }
}

.attachment-overview {
  width: 100%;
  .content {
    height: auto;
    max-height: 70vh;
    overflow: auto;
    margin: 1.5rem 0;
    padding: 0 20%;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .label-initial {
      background-color: $theme-green-1;
      width: 5px;
      height: 15px;
    }
  }
}

// --------------------------------------------------------------------------------
.submission-table {
  // .table-submission.adjust-table {
  //   transform: translateY(42px);
  // }
  .table-submission {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    thead.adjust-tableheader {
      // Fixes issue of sectionHeader not positioning correctly in Safari.
      transform: translateY(42px);
    }
    thead {
      z-index: 2 !important;
      .header-row .middle-columns {
        resize: horizontal;
        overflow-x: hidden;
      }
      .section-titles-row {
        position: relative;
        transform: translateY(-85px);
        background: $theme-white-1 !important;
        th {
          background: $theme-white-1 !important;
          height: 43px;
        }
      }
    }
    thead.header-row::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      border-bottom: 1px solid $theme-grey-13;
      pointer-events: none;
    }
    thead th.cell-border-top::after {
      content: '';
      position: absolute;
      right: 0;
      // bottom: 0;
      left: 0;
      top: 0;
      border-top: 1px solid $theme-green-1;
      pointer-events: none;
    }
    thead th.cell-border-right::after,
    tbody td.cell-border-right::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      border-right: 1px solid $theme-grey-13;
      pointer-events: none;
    }
    thead th.cell-border-left::after,
    tbody td.cell-border-left::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      border-left: 1px solid $theme-grey-13;
      pointer-events: none;
    }
    tbody.adjust-tablebody {
      position: relative;
      // transform: translateY(-42px);
    }
    .sticky-cell {
      position: sticky;
      z-index: 1;
    }
    .non-sticky-cell {
      position: relative;
      z-index: 0;
    }

    .no-record-found {
      position: fixed;
      width: calc(100% - (250px + 40px)); // screenWidth - (sidebarWidth + mainLayourPadding)
      align-content: center;
      text-align: center;
    }

    th,
    td {
      height: 42px;
      min-width: 50px;
      background: $theme-white-1;
      padding: 8px 12px !important;
      vertical-align: middle;
      font-family: 'PlusJakartaSans Regular';
      font-size: 1rem;
      letter-spacing: 1px;
      color: $theme-grey-9 !important;
      .border-right-grey-10-h-2 {
        overflow-x: hidden;
        padding-left: 10px;
        padding-right: 10px;
      }
      &.min-width-160 {
        min-width: 10rem;
      }
      .main-div {
        overflow-x: hidden;
      }
    }
    tr.header-row th {
      background-color: $theme-white-2 !important;
    }
    th {
      color: $theme-grey-7 !important;
      height: 42px;
      text-wrap: nowrap;
      font-weight: 400;
      &.filter-field {
        background-color: $theme-white-1 !important;
      }
    }

    th.nowrap,
    td.nowrap {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 50rem;
      .show-edit:empty {
        &::after {
          content: 'Edit here';
          color: $theme-grey-10;
          font-style: italic;
          border: 1px dashed $theme-grey-1;
          padding: 6px 14px;
        }
      }
    }
  }
}

.submission-report-modal-actions-icons {
  position: absolute;
  bottom: 3rem;
  right: 3rem;
}

// -----------------------------------------------------------------------
.back-circular-grey {
  background: $theme-white-5;
  padding: 10px;
  border-radius: 50%;
}

.message-indicator {
  background-color: $theme-green-1;
  height: 10px;
  width: 10px;
  display: block;
  border-radius: 100%;
  position: absolute;
  top: 4%;
  left: 70%;
}
// ------------------------- form-comment-dialog-under-report-submission-table-------------------
.submission-comments-container {
  .submission-comments {
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
    margin: 1.5rem 0;
    > div {
      width: 70%;
    }
    .profile-image {
      background: $theme-green-1;
      border-radius: 50%;
      width: 30px;
      height: 30px;
    }
    .profile-image::after {
      background-color: $theme-green-1;
      height: 10px;
      width: 10px;
      display: block;
      border-radius: 100%;
      position: absolute;
      top: 4%;
      left: 70%;
    }
    .received,
    .sent {
      padding: 1rem 0.75rem;
      margin-top: 1rem;
      display: flex;
      background: $theme-white-5;
      .start {
        width: 15%;
        display: flex;
        justify-content: center;
      }
      .end {
        flex-grow: 1;
        .top-section {
          height: 30px;
          .title {
            font-weight: bold;
          }
        }
      }
    }
    .received {
      background: $theme-white-5;
      .attachmentContainer {
        background: $theme-white-1;
      }
    }
    .sent {
      background: $theme-blue-6;
      align-self: end;
      margin-inline-end: 16px;
      span {
        color: $theme-white-1 !important;
      }
      .attachmentContainer {
        background: $theme-blue-5;
      }
    }
  }
  .form-comment-icons {
    position: absolute;
    top: calc(50% - 0.675rem);
    display: flex;
    gap: 0.5rem;
    height: 1.25rem;

    &.form-comment-icons-ltr {
      right: 10px;
    }
    &.form-comment-icons-rtl {
      left: 10px;
    }

    .send-icon {
      height: 1.5rem;
      cursor: pointer;
    }
  }
  .attachmentContainer {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 10px;
    max-height: 150px;
    overflow: auto;
    background: $theme-white-3;
    width: fit-content;
    div {
      direction: ltr;
    }
    .imgThumbnail {
      object-fit: contain;
      height: 5rem;
    }
  }
}
